import React, {useEffect, useState} from "react";
import './NewQuestion.css';
import {createQuestion, fetchQuestions, updateQuestion} from "./admin-api";
import {useDispatch, useSelector} from "react-redux";
import {withRouter, useParams} from "react-router";


const NewQuestion = ({
                         history,
                         initial_state = {
                             question_text: "",
                             answers: [
                                 {
                                     text: "",
                                     points: 0
                                 },
                                 {
                                     text: "",
                                     points: 0
                                 },
                                 {
                                     text: "",
                                     points: 0
                                 },
                                 {
                                     text: "",
                                     points: 0
                                 },
                                 {
                                     text: "",
                                     points: 0
                                 },
                                 {
                                     text: "",
                                     points: 0
                                 }
                             ]
                         }
                     }) => {
    const [question, setQuestion] = useState(initial_state)
    const dispatch = useDispatch()
    const questions = useSelector(state => state.admin.questions)
    const params = useParams()

    useEffect(() => {
        fetchQuestions(dispatch)
    }, [])

    useEffect(() => {
        if (params.question_id && !isNaN(params.question_id)) {
            const id = parseInt(params.question_id)
            const matching_question = questions.find(q => q.id === id)
            console.log(matching_question)
            if (matching_question) {
                setQuestion(matching_question)
            }
        }
    }, [params.question_id, questions])


    const editQuestion = text => setQuestion({...question, question_text: text})
    const editAnswer = (index, updateObject) => {
        const answersCopy = [...question.answers]
        const answerCopy = {...question.answers[index], ...updateObject}
        answersCopy.splice(index, 1, answerCopy)
        setQuestion({...question, answers: answersCopy})
    }

    const save = async () => {
        console.log(JSON.stringify(question, null, '\t'));
        if (params.question_id && !isNaN(params.question_id)) {
            updateQuestion(dispatch, question);
        } else {
            createQuestion(dispatch, question);
        }

        history.replace({pathname: '/admin'})
    }

    return (
        <div className="question-box">
            <span className="question-label label">Pytanie</span>
            <input className="question-input input"
                   value={question.question_text}
                   onChange={e => editQuestion(e.target.value)}/>

            {new Array(6).fill(0).map((a, index) => {
                return (
                    <>
                        <span className="answer-label label">Odpowiedź {index + 1}</span>
                        <div className="answer-box">
                            <input className="answer-input input"
                                   value={question.answers[index].text}
                                   onChange={e => editAnswer(index, {text: e.target.value})}/>
                            <span className="answer-points-label label">Punkty:</span>
                            <input className="answer-points input" type="number" max="100"
                                   value={question.answers[index].points}
                                   onChange={e => editAnswer(index, {points: e.target.value})}/>
                        </div>
                    </>
                )
            })}

            <button className="save" onClick={save}>Zapisz</button>
        </div>
    )
}

export default withRouter(NewQuestion);
