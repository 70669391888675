import * as actionTypes from "../store/actions/actionTypes";
import {API_BASE_URL} from "../index";


export const updateState = async (state) => {
    try {
        const response = await fetch('//' + API_BASE_URL + '/api/admin/state', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(state) // body data type must match "Content-Type" header
        });
    } catch (e) {
        alert(e);
    }
}

export const fetchQuestions = async (dispatch) => {
    try {
        const response = await fetch('//' + API_BASE_URL + '/api/admin/questions', {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
        // return response.json();

        const questions = await response.json()
        dispatch({type: actionTypes.SET_ALL_QUESTIONS, payload: questions})
    } catch (e) {
        alert(e);
    }
}

export const createQuestion = async (dispatch, question) => {
    try {
        const response = await fetch('//' + API_BASE_URL + '/api/admin/questions', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(question) // body data type must match "Content-Type" header
        });
        fetchQuestions(dispatch);

    } catch (e) {
        alert(e);
    }
}

export const updateQuestion = async (dispatch, question) => {
    try {
        const response = await fetch('//' + API_BASE_URL + '/api/admin/questions/' + question.id, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(question) // body data type must match "Content-Type" header
        });
        fetchQuestions(dispatch);

    } catch (e) {
        alert(e);
    }
}

export const deleteQuestion = async (dispatch, question_id) => {
    try {
        const response = await fetch('//' + API_BASE_URL + '/api/admin/questions/' + question_id, {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
        fetchQuestions(dispatch);
    } catch (e) {
        alert(e);
    }
}
