import * as actionTypes from '../actions/actionTypes';
import {STORE_UPDATE} from "../actions/actionTypes";
import fail from "../../audio/fail.mp3";
import correct from "../../audio/good.mp3";
import {act} from "react-dom/test-utils";

const initialState = {
    currentQuestion: null,
    admin: {
        questions: [],
        gameQuestions: [],
        currentQuestionIndex: 0
    },
    team1: {
        name: 'team1',
        points: 0,
        fails: 0
    },
    team2: {
        name: 'team2',
        points: 0,
        fails: 0
    },
    sum: 0,
    gameStarted: false,
    inputValue: '',
    activeTeam: 'team1',
    winner: null,
    whosFirst: false,
    surname1: '',
    surname2: ''
};

const correctSound = new Audio(correct);
const failSound = new Audio(fail);

export const rootReducer = (state = initialState, action) => {
    console.log("ACTION: " + action.type)
    switch (action.type) {
        case actionTypes.SOCKET_UPDATE:
            console.log(action.payload)
            return rootReducer(state, JSON.parse(action.payload.message))

        case actionTypes.STORE_UPDATE:
            console.log("WS UPDATE!")
            console.log("BEFORE:")
            console.log(state)
            const afterState = {
                ...state,
                ...action.payload
            }
            console.log("AFTER:")
            console.log(afterState)

            const activeTeam = state.activeTeam

            if (!state.admin.questions.length) {
                if (action.payload.failTimestamp) {
                    if (state.failTimestamp && state.failTimestamp < action.payload.failTimestamp) {
                        console.log("FAIL SOUND")
                        failSound.play()
                    }
                }
                if (action.payload.correctTimestamp) {
                    if (state.correctTimestamp && state.correctTimestamp < action.payload.correctTimestamp) {
                        console.log("CORRECT SOUND")
                        correctSound.play()
                    }
                }
            }

            return {
                ...state,
                ...action.payload
            }
        case actionTypes.SET_ALL_QUESTIONS:
            console.log("questions updated")
            return {
                ...state,
                admin: {
                    ...state.admin,
                    questions: action.payload
                }
            }
        case actionTypes.WHOS_FIRST:
            return {
                ...state,
                activeTeam: action.payload,
                whosFirst: false
            };
        case actionTypes.INPUT_CHANGE:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        case actionTypes.STARG_GAME:
            return {
                ...state,
                team1: {
                    ...state.team1,
                    name: state.surname1
                },
                team2: {
                    ...state.team2,
                    name: state.surname2
                },
                whosFirst: true,
                gameStarted: true
            };

        // case actionTypes.NEW_QUESTION:
        //     const question =
        //         state.questions[Math.floor(Math.random() * state.questions.length)];
        //     // const newQuestions = questions.filter(q => q !== question);
        //     const newQuestions = []
        //     return {
        //         ...state,
        //         whosFirst: state.gameStarted,
        //         currentQuestion: question,
        //         questions: newQuestions,
        //         team1: {
        //             ...state.team1,
        //             fails: 0
        //         },
        //         team2: {
        //             ...state.team2,
        //             fails: 0
        //         }
        //     };
        // case actionTypes.CORRECT_ANSWERS:
        //     const activeTeam = state.activeTeam;
        //     return {
        //         ...state,
        //         inputValue: '',
        //         [activeTeam]: {
        //             ...state[activeTeam],
        //             points: state[activeTeam].points + action.payload.answer[0].points
        //         }
        //     };

        case actionTypes.ADD_FAILS:
            const active = state.activeTeam;
            return {
                ...state,
                inputValue: '',
                activeTeam: active,
                [active]: {
                    ...state[active],
                    fails: state[active].fails + 1
                }
            };
        case actionTypes.HANDLE_CHECK_FAILS:
            const team = action.payload;
            return {
                ...state,
                activeTeam: team,
                [team]: {
                    ...state[team],
                    fails: 2
                }
            };
        case actionTypes.NEW_WINNER:
            return {
                ...state,
                winner: action.payload
            };
        default:
            return state;
    }
};
