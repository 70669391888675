import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {fetchQuestions} from "./admin-api";
import * as actionTypes from "../store/actions/actionTypes";
import QuestionsList from "../components/QuestionsList/QuestionsList";
import './AdminGame.css'
import {localDispatch, socketDispatch, withBlindAnswers} from "./sync-utils";
import ManualPointsEdit from "../components/ManualPointsEdit";

const AdminGame = () => {
    const store = useSelector(state => state)
    const [question, setQuestion] = useState()
    const dispatch = useDispatch()

    const notifyFail = (teamName) => {
        socketDispatch(dispatch, store, {
            [teamName]: {
                fails: store[teamName].fails + 1,
                name: store[teamName].name,
                points: store[teamName].points
            },
            failTimestamp: new Date().getTime(),
        })
    }

    const failSoundOnly = () => {
        socketDispatch(dispatch, store, {
            failTimestamp: new Date().getTime(),
        })
    }

    const notifyCorrect = (answer) => {
        const answerIndex = store.currentQuestion.answers.findIndex(a => a.id === answer.id)
        console.log("id: " + answerIndex)

        const currentQuestionCopy = {...store.currentQuestion}
        currentQuestionCopy.answers = [...currentQuestionCopy.answers]
        currentQuestionCopy.answers.splice(answerIndex, 1, {...answer, correct: true, name: answer.text})
        socketDispatch(dispatch, store, {
            currentQuestion: currentQuestionCopy,
            sum: store.sum + (currentQuestionCopy.answered ? 0 : answer.points),
            // [store.activeTeam]: {
            //     fails: store[store.activeTeam].fails,
            //     name: store[store.activeTeam].name,
            //     points: store[store.activeTeam].points += answer.points
            // },
            correctTimestamp: new Date().getTime(),
        })
    }

    const grantSumToTeam = (teamName) => {
        const team1GrantedPoints = teamName == "team1" ? store.sum : 0;
        const team2GrantedPoints = teamName == "team2" ? store.sum : 0;
        const currentQuestionCopy = {...store.currentQuestion}
        currentQuestionCopy.answered = true;

        socketDispatch(dispatch, store, {
            currentQuestion: currentQuestionCopy,
            team1: {
                fails: store.team1.fails,
                name: store.team1.name,
                points: store.team1.points + team1GrantedPoints,
            },
            team2: {
                fails: store.team2.fails,
                name: store.team2.name,
                points: store.team2.points + team2GrantedPoints,
            },
        })
    }

    const nextQuestion = () => {
        let currentQuestionIndex = store.admin.currentQuestionIndex;
        let currentQuestion = store.currentQuestion;

        if (currentQuestionIndex < store.admin.gameQuestions.length - 1) {
            currentQuestionIndex++;
            localStorage.setItem('currentQuestionIndex', currentQuestionIndex);
            currentQuestion = withBlindAnswers(store.admin.gameQuestions[currentQuestionIndex])
        }

        socketDispatch(dispatch, store, {
            currentQuestion: currentQuestion,
            team1: {
                fails: 0,
                name: store.team1.name,
                points: store.team1.points,
            },
            team2: {
                fails: 0,
                name: store.team2.name,
                points: store.team2.points,
            },
            sum: 0,
        })
        localDispatch(dispatch, {
            admin: {
                ...store.admin,
                currentQuestionIndex
            }
        })
    }

    const switchTeams = () => {
        socketDispatch(dispatch, store, {
            activeTeam: store.activeTeam === "team1" ? "team2" : "team1"
        })
    }

    useEffect(() => {
        if (store.currentQuestion !== null && store.admin.questions && store.admin.questions.length > 0) {
            setQuestion(store.admin.questions.find(q => q.id === store.currentQuestion.id))
        }
    }, [store.admin.questions, store.currentQuestion])

    return store.currentQuestion && store.gameStarted && (
        <div className="admin-game">
            <h1>GRA W TOKU</h1>
            <hr className="spacer"/>
            <h2>Pytanie {store.admin.currentQuestionIndex + 1} / {store.admin.gameQuestions.length}:</h2>
            <h1>{question && question.question_text}</h1>
            <div className="answer-buttons">
                {question && question.answers.filter(a => a.text !== "").map(answer => (
                    <button
                        className="answer-button"
                        key={answer.id}
                        disabled={(store.currentQuestion.answers.find(a => a.id === answer.id) || {}).correct}
                        onClick={() => notifyCorrect(answer)}>{answer.text}</button>

                ))}
                {/*<button className="answer-button wrong" onClick={notifyFail}>Pudło!</button>*/}
            </div>

            <hr className="spacer"/>
            <h2>Zalicz pudło drużynie:</h2>
            <div className="admin-game--curent-team-box">
                <button
                    className="answer-button wrong"
                    onClick={() => notifyFail("team1")}>{store.team1.name}</button>
                <button
                    className="answer-button wrong"
                    onClick={() => notifyFail("team2")}>{store.team2.name}</button>
                <button
                    className="answer-button wrong"
                    onClick={() => failSoundOnly()}>Sam dzwiek Pudła</button>
            </div>

            <hr className="spacer"/>

            <h2>Przyznaj punkty drużynie:</h2>
            <div className="admin-game--curent-team-box">
                <button
                    className="answer-button"
                    disabled={store.currentQuestion.answered}
                    onClick={() => grantSumToTeam("team1")}>{store.team1.name}</button>
                <button
                    className="answer-button"
                    disabled={store.currentQuestion.answered}
                    onClick={() => grantSumToTeam("team2")}>{store.team2.name}</button>
            </div>

            <hr className="spacer"/>

            <div className="next-question-cointainer">
                <button
                    className="next-question-button"
                    onClick={() => nextQuestion()}>Przejdź do następnego pytania</button>
            </div>

            <hr className="spacer"/>

            <ManualPointsEdit/>

            <hr className="spacer"/>


            {/*<div>*/}
            {/*    <button*/}
            {/*        className="next-question-button"*/}
            {/*        disabled={store.admin.gameQuestions && store.admin.currentQuestionIndex >= store.admin.gameQuestions.length - 1}*/}
            {/*        // onClick={nextQuestion}>Następne Pytanie</button>*/}
            {/*</div>*/}
        </div>
    ) || <div/>
}

export default AdminGame
