import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {socketDispatch} from "../views/sync-utils";

const ManualPointsEdit = () => {
    const store = useSelector(store => store)
    const dispatch = useDispatch()
    const [team1Points, setTeam1Points] = useState(store.team1.points);
    const [team2Points, setTeam2Points] = useState(store.team2.points);

    useEffect(() => {
        setTeam1Points(store.team1.points);
        setTeam2Points(store.team2.points);
    }, [store.team1.points, store.team2.points])

    const save = () => {
        socketDispatch(dispatch, store, {
            team1: {
                ...store.team1,
                points: team1Points
            },
            team2: {
                ...store.team2,
                points: team2Points
            }
        })
    }

    return (
        <div className="next-question-cointainer">
            <h1>Zmień punkty drużyn ręcznie:</h1>
            <div>
                <label>
                    <span className="inline-label">{store.team1.name}</span>
                    <input className="input" type="integer" value={team1Points}
                           onChange={e => setTeam1Points(parseInt(e.currentTarget.value))}/>
                </label>
            </div>
            <div>
                <label>
                    <span className="inline-label">{store.team2.name}</span>
                    <input className="input" type="integer" value={team2Points}
                           onChange={e => setTeam2Points(parseInt(e.currentTarget.value))}/>
                </label>
            </div>
            <button
                className="next-question-button"
                onClick={() => save()}>Zapisz
            </button>
        </div>
    )
}

export default ManualPointsEdit
