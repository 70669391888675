import React from 'react';
import './SideBoard.css';
import {useSelector} from "react-redux";

const SideBoard = ({ team: { name, points, fails } }) => {
  const gameStarted = useSelector(state => state.gameStarted)

  const failsTab = [];
  for (let i = 0; i < fails; i++) {
    failsTab.push({
      text: 'X',
      id: i
    });
  }
  const failsToRender = failsTab.map(fail => (
    <div key={fail.id} className="fail">
      {fail.text}
    </div>
  ));

  return gameStarted && (
    <div className="sideBoard">
      <div className="fails">{failsToRender}</div>
      <div className="points">
        <span className="points-value">{points}</span>
        <span className="teamName">{name}</span></div>
    </div>
  );
};

export default SideBoard;
