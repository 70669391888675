export const WHOS_FIRST = 'WHOS_FIRS';
export const INPUT_CHANGE = 'INPUT_CHANGE';
export const STARG_GAME = 'START_GAME';
export const NEW_QUESTION = 'NEW_QUESTION';
export const CORRECT_ANSWERS = 'CORRECT_ANSWERS';
export const ADD_FAILS = 'ADD_FAILS';
export const HANDLE_CHECK_FAILS = 'HANDLE_CHECK_FAILS';
export const NEW_WINNER = 'NEW_WINNER';
export const STORE_UPDATE = 'STORE_UPDATE';
export const SOCKET_UPDATE = 'REDUX_WEBSOCKET::MESSAGE';
export const SET_ALL_QUESTIONS = 'SET_ALL_QUESTIONS';
