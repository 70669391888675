import React from 'react';
import './WaitPage.css';
import {connect} from 'react-redux';

const WaitPage = ({start, surname1, surname2, change}) => {

    return (
        <div className="startingPage">
                <h1 className="familiada">Familiada</h1>
                <h1 className="wait">Zaraz zaczynamy!</h1>
                <h1 className="wait">Prosze czekac...</h1>
        </div>
    );
};

export default WaitPage
