import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Game from './views/Game';
import Admin from './views/Admin';
import {rootReducer} from './store/reducers/rootReducer';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
// import reduxWebsocket from 'react-redux-websocket';
import reduxWebsocket from "@giantmachines/redux-websocket";
import {connect} from "@giantmachines/redux-websocket";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import NewQuestion from "./views/NewQuestion";
import WindowFocusGuard from "./components/WindowFocusGuard";

export const API_BASE_URL = process.env.REACT_APP_API_URL || 'localhost:5000';

let ws_protocol = "ws://"
if (window.location.protocol === 'https:') {
    ws_protocol = "wss://"
}

// const socket = new WebSocket(ws_protocol + API_BASE_URL + '/api/ws');
// socket.addEventListener('open', function (event) {
//     console.log("socket open")
//     socket.send("init");
// });
// socket.onopen = () => {
//     console.log("socket open")
//     socket.send("init");
// }
// socket.onopen = () => {
//     console.log("socket open")
//     socket.send("init");
// }
//
// socket.onclose = () => {
//     console.log("socket close")
//     setTimeout(() => {
//         console.error("socke close")
//         // window.location.reload();
//     }, 3000);
// }

const reduxWebsocketMiddleware = reduxWebsocket({
    reconnectOnClose: true,
});
export const store = createStore(rootReducer, applyMiddleware(reduxWebsocketMiddleware));
store.dispatch(connect(ws_protocol + API_BASE_URL + '/api/ws'));

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/admin">
                    <Admin/>
                </Route>
                <Route exact path="/admin/preview">
                    <Game/>
                </Route>
                <Route exact path="/admin/questions/new">
                    <NewQuestion/>
                </Route>
                <Route exact path="/admin/questions/edit/:question_id">
                    <NewQuestion/>
                </Route>
                <Route path="/">
                    <WindowFocusGuard>
                    <Game/>
                    </WindowFocusGuard>
                </Route>
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
);
