import React from "react";
import {Link} from "react-router-dom";
import { withRouter } from "react-router";

import {useDispatch, useSelector} from "react-redux";
import {deleteQuestion} from "../../views/admin-api";
import './QuestionsList.css'
const QuestionsList = ({history}) => {
    const questions = useSelector(state => state.admin.questions)
    const dispatch = useDispatch()

    return (
        <div className="questions-list-component">
            <h1>List pytań:</h1>
            <ul className="questions-list">
                {questions && questions.length && questions.map(question => (
                    <li className="questions-list--item" key={question.id}>
                        <div>
                            <Link to={'/admin/questions/edit/' + question.id}>{question.question_text}</Link>
                            <ul>
                                {question.answers.filter(a => a.text !== "").map(answer => (
                                    <li key={answer.id} className="question-item--answer">{answer.text} [{answer.points}]</li>
                                ))}

                            </ul>

                        </div>
                        <button onClick={() => deleteQuestion(dispatch, question.id)}>Usuń!</button>
                    </li>
                ))}

            </ul>
        </div>
    )
}

export default withRouter(QuestionsList);
