import * as actionTypes from "../store/actions/actionTypes";
import { send } from '@giantmachines/redux-websocket';
import {updateState} from "./admin-api";

export const withBlindAnswers = question => {
    const publicQuestion = {...question}
    publicQuestion.answers = publicQuestion.answers && publicQuestion.answers
        .filter(answer => answer.text !== "")
        .map(answer => ({...answer, text: ""}))
    console.log(question);

    return publicQuestion;
}


export const socketDispatch = (dispatch, store, update) => {
    const publicStore = {...store, ...update}
    delete publicStore.admin

    console.log("SOCKET UPDATE")
    console.log(publicStore)

    const sendUpdate = () => updateState(
        {
            type: actionTypes.STORE_UPDATE,
            payload: {
                ...publicStore,
            },
        }
    );

    sendUpdate();
    setTimeout(sendUpdate, 150);
    setTimeout(sendUpdate, 300);
}

export const localDispatch = (dispatch, update) => {
    dispatch({
        type: actionTypes.STORE_UPDATE,
        payload: {
            ...update,
        },
        // socket: {
        //     send: false
        // }
    })
}
