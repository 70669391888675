import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {fetchQuestions} from "./admin-api";
import * as actionTypes from "../store/actions/actionTypes";
import QuestionsList from "../components/QuestionsList/QuestionsList";
import './Admin.css'
import AdminGame from "./AdminGame";
import {withBlindAnswers, socketDispatch, localDispatch} from "./sync-utils";
import {shuffle} from "lodash"

const Admin = () => {
    const store = useSelector(state => state)
    const dispatch = useDispatch()
    const [team1Name, setTeam1Name] = useState("")
    const [team2Name, setTeam2Name] = useState("")

    useEffect(() => {

        (async () => {
            fetchQuestions(dispatch)
            // const freshQuestions = await fetchQuestions(dispatch)
            // dispatch({ type: actionTypes.SET_ALL_QUESTIONS, payload: freshQuestions })
        })();

    }, [])

    const startGame = () => {
        const gameQuestions = shuffle(store.admin.questions)
        const currentQuestionIndex = 0;
        localStorage.setItem('gameQuestions', JSON.stringify(gameQuestions));
        localStorage.setItem('currentQuestionIndex', currentQuestionIndex);

        localDispatch(dispatch, {
            admin: {
                ...store.admin,
                gameQuestions,
                currentQuestionIndex,
            }
        })
        socketDispatch(dispatch, store, {
            gameStarted: true,
            team1: {
                fails: 0,
                name: team1Name,
                points: 0
            },
            team2: {
                fails: 0,
                name: team2Name,
                points: 0
            },
            currentQuestion: withBlindAnswers(gameQuestions[currentQuestionIndex]),
            sum: 0,
        })
    }

    const stopGame = () => {
        socketDispatch(dispatch, store, {
            gameStarted: false,
        })
        localStorage.removeItem('gameQuestions')
        localStorage.removeItem('currentQuestionIndex')
    }

    useEffect(() => {
        const gameQuestionStored = localStorage.getItem('gameQuestions')

        if (store.gameStarted && store.admin.gameQuestions.length === 0 && gameQuestionStored) {
            localDispatch(dispatch, {
                admin: {
                    gameQuestions: JSON.parse(gameQuestionStored),
                    currentQuestionIndex: parseInt(localStorage.getItem('currentQuestionIndex'))
                }
            });

        }
    }, [store.gameStarted])

    return (
        <div className="admin-game-view">
            <header className="admin-header">
                <h1 className="admin-header--title">Admin</h1>
                <button className="admin-header--refresh-button"
                        onClick={() => socketDispatch(dispatch, store, {})}>Re-sync
                </button>
            </header>
            {!store.gameStarted && (
                <div className="admin-teams-names">
                    <span className="label">Nazwa teamu 1:</span> <input className="input"
                                                                         onChange={e => setTeam1Name(e.target.value)}/>
                    <span className="label">Nazwa teamu 2:</span> <input className="input"
                                                                         onChange={e => setTeam2Name(e.target.value)}/>
                </div>
            )}
            <AdminGame className="admin-game"/>
            <div className="admin-game-buttons">
                <button className="new-game-start-button" disabled={store.gameStarted} onClick={startGame}>Zaczynij
                    grę!
                </button>
                <button className="new-game-start-button" disabled={!store.gameStarted} onClick={stopGame}>Zakończ
                    grę!
                </button>
            </div>
            <hr className="spacer"/>
            {!store.gameStarted && (
                <>
                    <Link className="new-question-link" to="/admin/questions/new">Dodaj pytanie</Link>
                    <QuestionsList/>
                </>
            )}
        </div>
    )
}

export default Admin
