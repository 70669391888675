import React, {useEffect, useState} from 'react';

// User has switched back to the tab

const WindowFocusGuard = ({children}) => {
    const onFocus = () => {
        setHasFocus(true)
    };

// User has switched away from the tab (AKA tab is hidden)
    const onBlur = () => {
        setHasFocus(false)
    };

    const [hasFocus, setHasFocus] = useState(document.hasFocus());

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    });

    return hasFocus ? children : (
            <div>
                <h1>Kliknij tutaj, aby wrócić do gry</h1>
            </div>
        )
}

export default WindowFocusGuard;
